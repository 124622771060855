<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">账号:</span>
        <el-input class="width_200" clearable v-model="search.username" placeholder="请输入标题"></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态:</span>
        <el-select v-model="search.status">
          <el-option label="全部" :value="-1"></el-option>
          <el-option v-for="item of statusList" :key="item" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" @selectionChange="selectChange" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="src" label="原图" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <ximg :src="scope.row.src" :width="30" :height="30" :enlarge="true" alt=""></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="addtime" label="时间" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{scope.row.addtime}} {{scope.row.finish_time?" / "+scope.row.finish_time:""}}
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-html="parseStatus(scope.row)"></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template #default="scope">
          <span class="operation" @click="showInfo(scope.row)">查看</span>
          <span class="operation" v-if="scope.row.status == 3" @click="refund(scope.row)">退款</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>

  <el-dialog :title="title" width="1000px" v-model="dialog">
    <el-row>
      <el-col  :span="8">
        <div>原图</div>
        <ximg :src="row.src" :width="200" :height="200" :enlarge="true" alt=""></ximg>
      </el-col>
      <el-col :span="8">
        <div>合成图</div>
        <ximg :src="row.dst" :width="200" :height="200" :enlarge="true" alt=""></ximg>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">备注</el-col>
      <el-col :span="20">{{row.reson}}</el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "ai_ty_log",
  components: {
    page,
  },
  data() {
    return {
      pager:{total: 0, page: 1, rows: 20},
      search:{
        username:"",
        status:-1,
      },
      tableData:[],
      selectRows:[],
      statusList:[
        {label:"队列中",value:0},
        {label:"处理中",value:1},
        {label:"成功",value:2},
        {label:"失败",value:3},
        {label:"已退款",value:4},
      ],
      row:{},
      dialog:false,
      title:""
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    refund(row){
      this.$confirm("确定退款？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        httpClient("ai_ty_refund").post({id:row.id}).then((res)=>{
          if (res.code == 0){
            row.status = 4
            return this.$message({message:"退款成功",type:"success"})
          }else{
            return this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    showInfo(row){
      this.title = `用户:[${row.username}]`
      this.row = row
      this.dialog = true
    },
    parseStatus(row){
      // 0,1,2,3
      var title = this.statusList[row.status].label
      if (row.status == 0){
        return `<span>${title}</span>`
      }else if (row.status == 1){
        return `<span style='color:grey'>${title}</span>`
      }else if (row.status == 2){
        return `<span style='color: green'>${title}</span>`
      }else{
        return `<span style='color: red'>${title}</span>`
      }
    },
    getList(){
      var params = {
        pageid:this.pager.page-1,
        pcount:this.pager.rows,
      }
      params = {...params,...this.search}
      httpClient("ai_ty_log").post(params).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
  }
};
</script>

<style>

/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 300px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 300px;
  display: block;
}
.el-row{margin-bottom: 10px;}
.hot_item .el-badge__content{
  margin-top:10px; margin-right:-10px; color:#FFFFFF; background: red;
}
</style>